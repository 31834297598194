<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais da Página
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-6">
								<FormInput :required=true rules="string" label="Título" placeholder="" info="" v-model="model.name" :slug_function=true />
							</div>			
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1, label: 'Mundo da Carabina'}, {id: 2,label: 'Aventura & Cia'}, {id: 3,label: 'Lexo'}]" v-model="model.store_id"  type="number" />	
							</div>						
							<div class="col-12">
								<FormTextarea state="paginas_especiais" upload_type="editor_special" :required=false label="Descrição" v-model="model.description" :rows=3 placeholder="Digite aqui um texto sobre essa página" info="" type="editor" />
							</div>
							<div class="col-6">
								<FormInputGroup :required=true rules="string" label="Slug" placeholder="" info="" position="left" text="https://*.com.br" v-model="model.slug" />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
							</div>
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Metatags -->
				<div class="hide card card-base">
					<div class="card-header">
						MetaTags
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<Metatags v-model="model.metatags"></Metatags>
						<div class="row">
							<div class="col-12">
								<FormInput :required=false rules="string" label="Canonical Link" placeholder="" info="Informe o link completo com https" v-model="model.canonical" />
							</div>	
						</div>	
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Filtros -->
				<div class="hide card card-base">
					<div class="card-header">
						Filtros
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-12">
								<div class="form-group mb-0"> 
									<label class="mb-1">Contém a Palavra</label>
									<MultiSelect state="paginas_especiais" tipo="filters_in" :dados="[]" :required=false :field_required=false :multiple=true :insert=true :modo_tag=true :clear_on_enter=true></MultiSelect>
								</div>
							</div>
							<div class="col-12 mt-2">
								<div class="form-group mb-0"> 
									<label class="mb-1">Não Contém a Palavra</label>
									<MultiSelect state="paginas_especiais" tipo="filters_notin" :dados="[]" :required=false :field_required=false :multiple=true :insert=true :modo_tag=true  :clear_on_enter=true></MultiSelect>
								</div>
							</div>
						</div>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Categorias -->
				<div class="hide card card-base">
					<div class="card-header">
						Categorias
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<Categorias :permissao=permissao state="paginas_especiais" :selecionados="categorias_selecionadas" :selecionavel=true :store_enabled=model.store_id />
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Marcas -->
				<div class="hide card card-base">
					<div class="card-header">
						Marcas
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelect state="paginas_especiais" tipo="marcas_selecionadas" :dados="marcas" :field_required=false :multiple=true :required=false :excluir_validation=false></MultiSelect>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Campanhas -->
				<div class="hide card card-base">
					<div class="card-header">
						Campanhas
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelect state="paginas_especiais" tipo="campanhas_selecionadas" :dados="campanhas" :field_required=false :multiple=true :required=false :excluir_validation=false></MultiSelect>
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import {Slug} from "./../../../common/utils";
import url_prefix from "./../../../assets/json/urls.json";
import Metatags from "./../../common/MetaTags";
import MultiSelect from "./../../common/MultiSelect";
import Categorias from "./../../common/Categorias";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import FormInputGroup from "./../../common/form/InputGroup";
import FormTextarea from "./../../common/form/Textarea";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		FormInputGroup,
		FormTextarea,
		Metatags,
		MultiSelect,
		Categorias
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.paginas_especiais.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		categorias_selecionadas() {
			return this.$store.state.paginas_especiais.model.categorias_selecionadas;
		},
		marcas_selecionadas() {
			return this.$store.state.paginas_especiais.model.marcas_selecionadas;
		},
		campanhas_selecionadas() {
			return this.$store.state.paginas_especiais.model.campanhas_selecionadas;
		},
		categorias() {
			return this.$store.state.paginas_especiais.categorias;
		}
	},	
	data () {
		return {
			header_info: {
				title: "Páginas Especiais",
				chamada: "Gerencie as páginas especiais da sua loja",
				links: {
					back: "paginas.especiais.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Páginas Especiais",
					link: "/paginas/especiais"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral",
				"MetaTags",
				"Filtros",
				"Categorias",
				"Marcas",
				"Campanhas"
			],
			disabled_items: [],
			hidden_items: [],
			permissao: {},
			marcas: [],
			campanhas: [
				{id: "black-friday", name: "Black Friday"}, 
				{id: "natal", name: "Natal"}, 
				{id: "limpa-estoque", name: "Limpa Estoque"},
				{id: "aniversario", name: "Aniversário"}, 
				{id: "independencia", name: "Independência"}, 
				{id: "consumidor", name: "Consumidor"}, 
				{id: "oferta-da-hora", name: "Oferta da Hora"}
			],
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){			
				let model = this.model;

				// tratando filters in
				model.filters.in = [];
				model.filters_in.forEach(item =>{
					model.filters.in.push(item.name);
				});
				delete model.filters_in;
				// tratando filters not in
				model.filters.notin = [];
				model.filters_notin.forEach(item =>{
					model.filters.notin.push(item.name);
				});
				delete model.filters_notin;

				model.categorias = [];
				model.marcas = [];
				model.target = [];

				// marca
				model.marcas_selecionadas.forEach(item => {
					model.marcas.push(item.id);
				});

				// campanhas
				model.campanhas_selecionadas.forEach(item => {
					model.target.push(item.id);
				});

				// categoria
				model.categorias_selecionadas.forEach(item => {
					model.categorias.push(item.id);
				});

				// apaga os campos
				delete model.marcas_selecionadas;
				delete model.categorias_selecionadas;
				delete model.campanhas_selecionadas;

				// salva
				this.$store.dispatch("paginas_especiais/save", model);
			}
		},
		toSlug: function(){
			if(this.model.slug == ""){
				this.model.slug = url_prefix.special_page + Slug(this.model.name);
			}			
			if(this.model.metatags && this.model.metatags.title == null){
				this.model.metatags.title = this.model.name;
			}
		},
		updateDadosMultiselect: function(){
			// no caso do páginas especiais
		},
	},
	provide: function () {
		return {
			save: this.save,
			toSlug: this.toSlug,
			updateDadosMultiselect: this.updateDadosMultiselect
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("paginas_especiais/reset");
		let startingPromises = [
			// pega as categorias
			this.$store.dispatch("paginas_especiais/load_categorias"),
			// pega as marcas
			this.$store.dispatch("paginas_especiais/load_marcas").then(response => {
				self.marcas = response;
			}),
		];

		Promise.all(startingPromises).then(() => {
			if(this.$route.params.id != undefined && this.$route.params.id != "") {
				this.$store.dispatch("paginas_especiais/load", this.$route.params.id).then(response => {

					if(response.return_code == 1){
						let model = response.data;
					
						// tratando filters in
						model.filters_in = [];
						model.filters.in.forEach(item =>{
							model.filters_in.push({ id: null, name: item });
						});					
						// tratando filters not in
						model.filters_notin = [];
						model.filters.notin.forEach(item =>{
							model.filters_notin.push({ id: null, name: item });
						});

						// marcas	
						let marcas = []; 
						model.marcas.forEach((item) => {
							let marca = self.marcas.find(dado => {
								return item == dado.id;
							});
							if(marca){
								marcas.push(marca);
							}
						});
						self.$store.commit("paginas_especiais/update_marcas_selecionadas", marcas);
						// categoria
						var categorias = [];
						model.categorias.forEach(item => {
							categorias.push({id: item});
						});
						self.$store.commit("paginas_especiais/update_categorias_selecionadas",categorias);
						// campanhas	
						let campanhas = []; 
						model.target.forEach((item) => {
							let target = self.campanhas.find(dado => {
								return item == dado.id;
							});
							if(target){
								campanhas.push(target);
							}
						});
						self.$store.commit("paginas_especiais/update_campanhas_selecionadas", campanhas);

						response.data = model;
						
						self.$store.commit("paginas_especiais/loaded",response);
					}
					
				});
			}
		});

		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	watch: {
	
	}
};
</script>

<style scoped lang="scss">	
	@import "~@/assets/css/form.scss";
</style>
