<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais da Post
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-12">
								<FormInput :required=true rules="string" label="Título" placeholder="" info="" v-model="model.title" :slug_function=true />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1, label: 'Mundo da Carabina'}, {id: 2,label: 'Aventura & Cia'}, {id: 3,label: 'Lexo'}]" v-model="model.store_id"  type="number" @input="get_tags()" />
							</div>	
							<div class="col-6">
								<FormInputGroup :required=true rules="string" label="Data de Publicação" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="datetime" v-model="model.published_at" :index=0 />
							</div>							
							<div class="col-6">
								<Imagem :required=false rules="string" label="Capa" placeholder="" info="" v-model="model.featured_image" type="blog" :store_id="model.store_id" slug="featured_image"></Imagem>
							</div>
							<div class="col-6">
								<Imagem :required=false rules="string" label="Banner" placeholder="" info="" v-model="model.banner_image" type="blog" :store_id="model.store_id" slug="banner_image"></Imagem>
							</div>
							<div class="col-12">
								<div class="form-group mb-0"> 
									<label class="mb-1">Categorias</label>
									<MultiSelect state="blog" tipo="tags" :dados="tags" :required=false :field_required=false :multiple=true :insert=false :modo_tag=true></MultiSelect>
								</div>
							</div>
							<div class="col-12">
								<FormTextarea :required=false label="Chamada" v-model="model.resume" :rows=3 placeholder="Resuma em poucas palavras o seu texto" info="" type="textarea" />
							</div>
							<div class="col-12">
								<FormTextarea state="blog" upload_type="editor_blog" :required=false label="Texto" v-model="model.content" :rows=3 placeholder="Digite aqui o texto do seu POST" info="" type="editor" />
							</div>							
							<div class="col-12">
								<FormInputGroup :required=true rules="string" label="Slug" placeholder="" info="" position="left" text="https://*.com.br" v-model="model.slug" />
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
							</div>
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Metatags -->
				<div class="hide card card-base">
					<div class="card-header">
						MetaTags
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<Metatags v-model="model.metatags"></Metatags>					
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import {Slug} from "./../../../common/utils";
import url_prefix from "./../../../assets/json/urls.json";
import Metatags from "./../../common/MetaTags";
import MultiSelect from "./../../common/MultiSelect";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import FormInputGroup from "./../../common/form/InputGroup";
import FormTextarea from "./../../common/form/Textarea";
import Imagem from "./../../common/form/Imagem";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		FormInputGroup,
		FormTextarea,
		Metatags,
		Imagem,
		MultiSelect
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.blog.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		}
	},	
	data () {
		return {
			header_info: {
				title: "Blog",
				chamada: "Gerencie os posts do blog da sua loja",
				links: {
					back: "blog.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "blog",
					link: "/blog"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral",
				"MetaTags"
			],
			disabled_items: [],
			hidden_items: [],
			tags: [],
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){
				let model = Object.assign({}, this.model);
				model.tags.forEach(item => {
					delete item.id;
					delete item.required;
				});
				// salva
				this.$store.dispatch("blog/save", model);
			}
		},
		toSlug: function(){
			if(this.model.slug == ""){
				this.model.slug = url_prefix.blog_post + Slug(this.model.title);
			}			
			if(this.model.metatags && this.model.metatags.title == null){
				this.model.metatags.title = this.model.title;
			}
		},
		updateDadosMultiselect: function(objeto,tipo){
			this[tipo].push(objeto);
		},
		get_tags: function(){
			let self = this;
			// pega as tags
			this.$store.dispatch("blog/load_tags_data", self.model.store_id).then((response) => {
				response.forEach(item => {
					item.id = item.slug;
				});
				self.tags = response;
			});
		},
	},
	provide: function () {
		return {
			save: this.save,
			toSlug: this.toSlug,
			updateDadosMultiselect: this.updateDadosMultiselect
		};
	},
	created() {
		this.$store.dispatch("blog/reset");
		
		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			this.$store.dispatch("blog/load", this.$route.params.id).then(() => {
				this.get_tags();
			});
		}

		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	watch: {}
};
</script>

<style scoped lang="scss">	
	@import "~@/assets/css/form.scss";
</style>
