import router from "./../../router";
import api from "./../../common/api";
import alerts from "./../../common/alerts";
import model from "./../models/blog";
import config from "./../../assets/json/filters";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	load: function(state, id) {
		return api.request("blog/loaded", "admin/blog/post/" + id, "get").then(response => {
			return response.body;
		});
	},
	save: function(context, data) {
		api.request("blog/loaded", "admin/blog/post", (data.id == null) ? "post" : "put", data).then((response) => {
			//tratar a resposta
			if (response.body.return_code == 1) {
				alerts.alertTop("success", (data.id == null) ? "Post cadastrado com sucesso." : "Post atualizado com sucesso.");
				router.push({
					name: "blog.lista"
				});
			}
		});
	},
	update_ordem: function(state, data) {
		return api.request("blog/no_action", "admin/blog/posts/order", "put", data);
	},
	//lista
	// faz o load do filters.json
	load_filtros_data: (state) => {
		state.commit("data_filtros_loaded", config.blog);		
	},
	// faz o load dos registros do banco na listagem
	load_valores_data: (state,data) => { 
		let fields = [];
		config.blog.campos.forEach(item => {
			if(item.hasOwnProperty("field_object")){
				fields.push(item.field+"."+item.field_object);
			}else{
				fields.push(item.field);
			}
		});

		state.commit("clear_valores");
		api.request("blog/data_valores_loaded", "admin/blog/posts", "post", { fields: fields, "filters": data.filtros, "sort": data.opcoes.order_field + "," + data.opcoes.order, "limit": data.opcoes.limit, "page": data.opcoes.page });
	},
	// altera a página da listagem
	altera_pagina: (state,pagina) => {
		state.commit("altera_pagina", pagina);		
	},
	// altera o limit da listagem 
	altera_limit: (state,limit) => {
		state.commit("altera_limit", limit);		
	},
	// faz a marcação dos itens da listagem para ações em massa
	select_listagem: (state,id) => {
		state.commit("select_listagem",id);		
	},
	// [em massa] faz a marcação dos itens da listagem para ações em massa
	select_listagem_all: (state,ids) => {
		state.commit("select_listagem_all",ids);		
	},	
	// método switch na linha da listagem
	switch_listagem: (state,dados) => {				
		let data = {};
		data[dados.field] = dados.value;
		return api.request("blog/no_action", "admin/blog/posts", "put", { ids: [dados.id], data: data });
	},
	// método para atualização de status em massa 
	status_massa: (state,dados) =>{
		let data = {};
		data[dados.field] = dados.value;
		return api.request("blog/acao_massa", "admin/blog/posts", "put", { ids: dados.id, data: data });
	},
	excluir_massa: (state,dados) =>{		
		return api.request("blog/acao_massa", "admin/blog/posts", "delete", { ids: dados.id });
	},
	// método de exclusão de registro um por vez 
	excluir_registro: (state,id) => {
		return api.request("blog/no_action", "admin/blog/post/" + id, "delete");
	},
	sort_listagem: (state,field) => {
		state.commit("sort_listagem",field);
	},
	get_filtros_salvos: (state,config) => {
		return api.request("blog/get_filtros_salvos", "admin/filters/" + config.tipo + "/" + config.user_id, "get");
	},
	save_filter: (state, filtro) => {
		let method;
		if(filtro.id != null){
			method = "put";
		}else{
			method = "post";
		}		
		return api.request("blog/save_filter", "admin/filter", method, filtro);
	},
	excluir_filtro: function(state,id){
		return api.request("blog/delete_filter", "admin/filter/"+id, "delete", id);
	},
	filter_blog_data: function(state, dados){
		let text = dados.text;
		
		return api.request("blog/no_action", "admin/blog/posts", "post", { "filters": [{
			field: "title", 
			operator: "like", 
			type: "string", 
			value: text
		}], "sort": "title,asc", "limit": 999999, "page": 1 }).then(response => {
			if(response.body.return_code == 1 && response.body.data && response.body.data.items){
				return response.body.data.items;
			}else{
				return [];
			}
		});

	},
	load_tags_data: function(state, store_id) {
		return api.request("blog/no_action", "admin/blog/tags/"+store_id, "get").then(response => {
			if(response.body.return_code == 1){
				return response.body.data;
			}else{
				return [];
			}
		});
	},
	addTag: function(state,data){
		state.commit("addTag",{item: data.item, tipo: data.tipo});
		return data.item;
	},
};