<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header ref="header" :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />

		<div class="row dashboard">
			<div class="col my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Painel de Monitoramento
						<i class="fas fa-sync-alt ml-3" aria-hidden="true" v-on:click="monitoringOrders()"></i>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-2 my-2" v-for="(box, i) in box_data" :key=i>
								<section class="panel dash-info-rapida dash-purple" :class="[ box.total == 0 ? `inactive ${box.class}` : `${box.class}` ]">
									<div class="total pb-3">
										<strong>{{ box.total }}</strong>
									</div>
									<div class="value mt-3">
										<label>{{ box.label }}</label>
									</div>									
								</section>
								<div class="mt-2 mb-3 select_dash">
									<FormSelect :required=false rules="option" label="" info="" :options="print_qtd_options" v-model="box.qtd_print"  type="number" />
									<button class="btn mt-2 btn-secondary btn-block" @click="printOrder(box)">Imprimir Pedidos</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="row">
			<div class="col my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Insira os dados do Pedido
					</div>
					<div class="card-body p-0">
						<div class="row">
							<div class="col-6">
								<div class="row justify-content-center align-items-center">
									<div class="col-12 col-md-8 align-self-center mt-3 ml-sm-3">
										<div class="my-3">
											<FormInput ref="orderNumber" :required=true rules="string" label="Número do Pedido" placeholder="" info="" v-model="order_number" @change="getOrder" />
										</div>
										<div class="my-3">
											<FormInput ref="code" :required=true :disabled="order_number == ''" rules="string" label="Código de Rastreio" placeholder="" info="" v-model="tracking_code" />
										</div>
										<div class="my-3">
											<button class="btn btn-success btn-block" @click="sendOrder()">Enviar Pedido</button>
										</div>
									</div>
								</div>
							</div>
							<div class="col-6">
								<ul v-if="order">
									<template  v-if="order.invoices">
										<li v-for="(invoice,x) in order.invoices" :key=x><strong>NF:</strong> {{ invoice.range }}-{{ invoice.number }}</li>
									</template>
									<li><strong>Cliente:</strong> {{ order.customer }}</li>
									<li><strong>Documento:</strong> {{ order.document | cnpfcnpj }}</li>
									<li><strong>Telefone:</strong> <span v-for="(phone,i) in order.phones" :key="i">{{ phone.number | phone }}</span></li>
									<li><strong>Aos cuidados:</strong> {{ order.contact }}</li>
									<li><strong>Rua:</strong> {{ order.street }}, {{ order.number }} <template v-if="order.complement">{{ order.complement }}</template></li>
									<li><strong>Bairro:</strong> {{ order.neighborhood }} </li>
									<li><strong>Cidade:</strong> {{ order.city }} / {{ order.state }} </li>
									<li><strong>CEP:</strong> {{ order.postalcode | cep }}</li>
									<li><strong>Referência:</strong> {{ order.reference }} </li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-6 my-3" v-for="(pedido) in orders" :key="pedido.order_number">
				<div class="card card-view card-base">
					<div class="card-header">
						Pedido {{ pedido.order_number }} / {{ pedido.store_id | store }}
					</div>
					<div class="card-body p-0">
						<ul>
							<li>
								<strong>Código de rastreio:</strong> {{ pedido.tracking_code }}
								<button
									v-on:click.prevent="copyTrackingCode(pedido.tracking_code)"
									class="btn btn-info btn-sm mx-1 my-1">
									<i class="fas fa-save"></i>
								</button>
							</li>
							<li v-if="pedido.shipment.carrier == 'marketplace'">
								<strong>Imprimir PLP:</strong>
								<button
									v-on:click.prevent="getPLP( pedido.order_number )"
									class="btn btn-success btn-sm mx-1 my-1">
									<i class="fas fa-sent"></i>
								</button>
							</li>
							<li v-if="pedido.shipment.carrier == 'correios' && pedido.metadata.id_recibo">
								<strong>Imprimir PLP:</strong>
								<button
									v-on:click.prevent="getPLP( pedido.order_number )"
									class="btn btn-success btn-sm mx-1 my-1">
									<i class="fas fa-sent"></i>
								</button>
							</li>
							<template  v-if="pedido.invoices">
								<li v-for="(invoice,x) in pedido.invoices" :key=x><strong>NF:</strong> {{ invoice.range }}-{{ invoice.number }}</li>
							</template>
							<li><strong>Cliente:</strong> {{ pedido.customer }}</li>
							<li><strong>Documento:</strong> {{ pedido.document | cnpfcnpj }}</li>
							<li><strong>Telefone:</strong> <span v-for="(phone,i) in pedido.phones" :key="i">{{ phone.number | phone }}</span></li>
							<li><strong>Aos cuidados:</strong> {{ pedido.contact }}</li>
							<li><strong>Rua:</strong> {{ pedido.street }}, {{ pedido.number }} <template v-if="pedido.complement">{{ pedido.complement }}</template></li>
							<li><strong>Bairro:</strong> {{ pedido.neighborhood }} </li>
							<li><strong>Cidade:</strong> {{ pedido.city }} / {{ pedido.state }} </li>
							<li><strong>CEP:</strong> {{ pedido.postalcode | cep }}</li>
							<li><strong>Referência:</strong> {{ pedido.reference }} </li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import Filters from "../../../common/filters";
import Alerts from "../../../common/alerts";
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";


export default {
	name: "PedidosShipment",
	components: {
		Header,
		Breadcrumb,
		FormInput,
		FormSelect
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		usuario() {
			return this.$store.state.app.usuario;
		},
		box_data() {
			const box_data = this.$store.state.pedidos.shipment_dash;
			if(box_data) {
				return [
					{
						class: "dash-pink",
						label: "Hoje MDC",						
						total: box_data.today_mdc.total,
						slug: "today",
						ids: box_data.today_mdc.ids,
						qtd_print: 30
					},
					{
						class: "dash-pink",
						label: "Hoje Lexo",						
						total: box_data.today_lexo.total,
						slug: "today",
						ids: box_data.today_lexo.ids,
						qtd_print: 30
					},
					{
						class: "dash-pink",
						label: "Hoje ECON",						
						total: box_data.today_econ.total,
						slug: "today",
						ids: box_data.today_econ.ids,
						qtd_print: 30
					},
					{
						class: "dash-pink",
						label: "Hoje NOVO",						
						total: box_data.today_novo.total,
						slug: "today",
						ids: box_data.today_novo.ids,
						qtd_print: 30
					},
					{
						class: "dash-green",
						label: "Ventureshop",						
						total: box_data.ventureshop.total,
						slug: "ventureshop",
						ids: box_data.ventureshop.ids,
						qtd_print: 30
					},
					{
						class: "dash-green",
						label: "Amazon",
						total: box_data.amazon.total,
						slug: "amazon",
						ids: box_data.amazon.ids,
						qtd_print: 30
					},
					{
						class: "dash-green",
						label: "Olist",
						total: box_data.olist.total,
						slug: "olist",
						ids: box_data.olist.ids,
						qtd_print: 30					
					},
					{
						class: "dash-yellow",
						label: "Meli Jadlog MDC",
						total: box_data.meli_jadlog_mdc.total,
						slug: "meli_jadlog_mdc",
						ids: box_data.meli_jadlog_mdc.ids,
						qtd_print: 30
					},
					{
						class: "dash-yellow",
						label: "Meli Jadlog ECON",
						total: box_data.meli_jadlog_econ.total,
						slug: "meli_jadlog_econ",
						ids: box_data.meli_jadlog_econ.ids,
						qtd_print: 30
					},
					{
						class: "dash-yellow",
						label: "Meli Jadlog LEXO",
						total: box_data.meli_jadlog_lexo.total,
						slug: "meli_jadlog_lexo",
						ids: box_data.meli_jadlog_lexo.ids,
						qtd_print: 30
					},
					{
						class: "dash-yellow",
						label: "Meli Jadlog NOVO",
						total: box_data.meli_jadlog_novo.total,
						slug: "meli_jadlog_novo",
						ids: box_data.meli_jadlog_novo.ids,
						qtd_print: 30
					},
					{
						class: "dash-yellow",
						label: "Mercado Livre MDC",
						total: box_data.meli_mdc.total,
						slug: "meli_mdc",
						ids: box_data.meli_mdc.ids,
						qtd_print: 30
					},
					{
						class: "dash-yellow",
						label: "Mercado Livre ECON",
						total: box_data.meli_econ.total,
						slug: "meli_econ",
						ids: box_data.meli_econ.ids,
						qtd_print: 30
					},
					{
						class: "dash-yellow",
						label: "Mercado Livre LEXO",
						total: box_data.meli_lexo.total,
						slug: "meli_lexo",
						ids: box_data.meli_lexo.ids,
						qtd_print: 30
					},
					{
						class: "dash-yellow",
						label: "Mercado Livre NOVO",
						total: box_data.meli_novo.total,
						slug: "meli_novo",
						ids: box_data.meli_novo.ids,
						qtd_print: 30
					},
					{
						class: "dash-yellow",
						label: "Mercado Livre Flex",
						total: box_data.meli_urgente.total,
						slug: "meli_urgente",
						ids: box_data.meli_urgente.ids,
						qtd_print: 30
					},
					{
						class: "dash-yellow",
						label: "Shopee Urgente",
						total: box_data.shopee_urgente.total,
						slug: "shopee_urgente",
						ids: box_data.shopee_urgente.ids,
						qtd_print: 30
					},
					{
						class: "dash-red",
						label: "Jadlog MDC",
						total: box_data.jadlog_mdc.total,
						slug: "jadlog_mdc",
						ids: box_data.jadlog_mdc.ids,
						qtd_print: 30
					},
					{
						class: "dash-red",
						label: "Jadlog ECON",
						total: box_data.jadlog_econ.total,
						slug: "jadlog_econ",
						ids: box_data.jadlog_econ.ids,
						qtd_print: 30
					},
					{
						class: "dash-red",
						label: "Jadlog LEXO",
						total: box_data.jadlog_lexo.total,
						slug: "jadlog_lexo",
						ids: box_data.jadlog_lexo.ids,
						qtd_print: 30
					},
					{
						class: "dash-red",
						label: "Jadlog NOVO",
						total: box_data.jadlog_novo.total,
						slug: "jadlog_novo",
						ids: box_data.jadlog_novo.ids,
						qtd_print: 30
					},
					{
						class: "dash-red",
						label: "Correios MDC",
						total: box_data.correios_mdc.total,
						slug: "correios_mdc",
						ids: box_data.correios_mdc.ids,
						qtd_print: 30
					},
					{
						class: "dash-red",
						label: "Correios ECON",
						total: box_data.correios_econ.total,
						slug: "correios_econ",
						ids: box_data.correios_econ.ids,
						qtd_print: 30
					},
					{
						class: "dash-red",
						label: "Correios LEXO",
						total: box_data.correios_lexo.total,
						slug: "correios_lexo",
						ids: box_data.correios_lexo.ids,
						qtd_print: 30
					},
					{
						class: "dash-red",
						label: "Correios NOVO",
						total: box_data.correios_novo.total,
						slug: "correios_novo",
						ids: box_data.correios_novo.ids,
						qtd_print: 30
					},
					{
						class: "dash-blue",
						label: "Outros Marketplaces",
						total: box_data.others_marketplaces.total,
						slug: "others_marketplaces",
						ids: box_data.others_marketplaces.ids,
						qtd_print: 30
					},
					{
						class: "dash-blue",
						label: "Retirada",
						total: box_data.retirada.total,
						slug: "retirada",
						ids: box_data.retirada.ids,
						qtd_print: 30
					},
					{
						class: "dash-blue",
						label: "Motoboy",
						total: box_data.motoboy.total,
						slug: "motoboy",
						ids: box_data.motoboy.ids,
						qtd_print: 30
					},
					{
						class: "dash-blue",
						label: "Outros",
						total: box_data.others_store.total,
						slug: "others_store",
						ids: box_data.others_store.ids,
						qtd_print: 30
					},
					{
						class: "dash-pink",
						label: "Agendado",						
						total: box_data.schedule.total,
						slug: "schedule",
						ids: box_data.schedule.ids,
						qtd_print: 30
					},
				];
			}else{
				return [];
			}
		},
		print_qtd_options() {
			return [
				{
					id: 0,
					label: "Todos"
				},
				{
					id: 10,
					label: "10 Pedidos"
				},
				{
					id: 20,
					label: "20 Pedidos"
				},
				{
					id: 30,
					label: "30 Pedidos"
				},
				{
					id: 40,
					label: "40 Pedidos"
				},
				{
					id: 50,
					label: "50 Pedidos"
				}
			];
		}
	},
	data () {
		return {
			header_info: {
				title: "Despacho de Pedidos",
				chamada: "Envie os pedidos da sua loja",
				links: {

				}
			},
			breadcrumb_info: [
				{
					name: "Pedidos",
					link: "/pedidos"
				},
				{
					name: "Despachar",
					link: ""
				}
			],
			permissao: [],
			order_number: "",
			tracking_code: "",
			order: null,
			status_sent_id: null,
			orders: [],
			loop_monitoring: null
		};
	},
	methods: {
		copyTrackingCode: function(code){

			let input = document.createElement("input");
			document.getElementsByTagName("body")[0].append(input);
			input.value = code;
			input.select();
			document.execCommand("copy");
			input.remove();

			Alerts.alertTop("success","Código de rastreio copiado com sucesso!");
		},
		resetOrder: function(first){
			this.order_number = "";
			this.tracking_code = "";
			this.order = {
				id: null,
				order_number: null,
				store_id: null,
				marketplace: null,
				shipment: null,
				customer: null,
				document: null,
				phones: [],
				contact: null,
				street: null,
				number: null,
				complement: null,
				neighborhood: null,
				city: null,
				state: null,
				postalcode: null,
				reference: null,
				tracking_code: null,
				invoices: []
			};
			if(!first){
				this.$refs.orderNumber.$forceUpdate();
				this.$refs.code.$forceUpdate();
			}
		},
		async getOrder(order){
			if(order != ""){
				const pedido = await this.$store.dispatch("pedidos/order_shipment",order);
				if(pedido){
					if(pedido.status.type == "in-separation"){
						for(const _order in this.order){
							switch(_order){
							case "id":
							case "order_number":
							case "tracking_code":
							case "store_id":
							case "marketplace":
							case "shipment":
								this.order[_order] = pedido[_order];
								break;
							case "invoices":
								if(pedido["invoices"].length > 0){
									this.order[_order] = pedido[_order];
								}else{
									this.order[_order] = null;
								}
								break;
							case "customer":
								this.order[_order] = pedido.customer.name;
								break;
							case "document":
								this.order[_order] = pedido.customer.document;
								break;
							default:
								this.order[_order] = pedido.address[_order];
								break;
							}
						}
						if(pedido.tracking_code){
							this.tracking_code = pedido.tracking_code;
						}
						if(this.order.shipment.carrier == "marketplace" || this.order.shipment.carrier == "correios"){
							this.getPLP(this.order.order_number);
						}
					}else{
						Alerts.alertTop("error","Para realizar esse procedimento o pedido deve estar com status Em Separação");
						this.resetOrder();
						this.$refs.orderNumber.focusInput();
						return;
					}
					this.$refs.code.focusInput();
				}else{
					Alerts.alertTop("error","Houve um problema ao verificar os dados do pedido");
				}
			}
		},
		printOrder(box){

			let ids;
			
			if(box.qtd_print > 0){
				ids = box.ids.slice(0, box.qtd_print);
			}else{
				ids = box.ids;
			}

			this.$store.dispatch("pedidos/imprimir_massa", {field: "", value: 0, id: ids });

		},
		async sendOrder(){
			const self = this;
			const extra_data = {
				tracking_code: this.tracking_code
			};
			await this.$store.dispatch("pedidos/update_status_shipment",{
				order_id: self.order.id,
				status_id: self.status_sent_id,
				user_id: self.usuario.id,
				data: extra_data
			}).then(response => {
				if(response){
					if(response.ok){
						Alerts.alertTop("success", "Pedido enviado com sucesso.");
						self.order.tracking_code = self.tracking_code;
						self.orders.unshift(Object.assign({},self.order));
						// if(self.order.shipment.carrier == "marketplace"){
						// 	self.getPLP(self.order.order_number, self.order.marketplace.marketplace_id);
						// }
						self.resetOrder();
						self.$refs.orderNumber.focusInput();
					}
				}
			});
		},
		async getPLP( order_number ){
			try {
				await this.$store.dispatch("pedidos/get_plp", order_number).then(response => {
					if(response){
						if(response.source && response.source == "correios"){
							const a = document.createElement("a");
							a.href = `data:${response.mimetype};base64,${response.content}`;
							a.download = `plp-pedido-${order_number}.pdf`;
							a.click();
						}else{
							const a = document.createElement("a");
							a.href = `data:${response.mimetype};base64,${response.content.$binary.base64}`;
							a.download = `plp-pedido-${order_number}.pdf`;
							a.click();
						}
					}else{
						Alerts.alertTop("error", "Não foi possível acessar a etiqueta");
					}
				});
			} catch {
				Alerts.alertTop("error", "Não foi possível acessar a etiqueta");
			}

		},
		monitoringOrders() {
			this.$store.dispatch("pedidos/shipment_monitoring");
		},
	},
	filters: Filters,
	created() {
		const self = this;
		this.$store.dispatch("pedidos/reset");
		if(this.permissoes.permissoes) {
			this.permissao = this.permissoes.permissoes[this.config.modulo_permissao];
		}
		this.$store.dispatch("pedidos_status/get_status_by_type","sent").then(response => {
			if(response && response.length > 0){
				self.status_sent_id = response[0].id;
			}else{
				Alerts.alertTop("error","Não foi possível recuperar o status do tipo Enviado");
			}
		});
		this.resetOrder(true);
		self.monitoringOrders();
		this.loop_monitoring = setInterval(function(){ self.monitoringOrders(); }, 1000 * 60 * 30);
	},
	mounted() {
		this.$refs.orderNumber.focusInput();
	},
	beforeDestroy() {
		clearInterval(this.loop_monitoring);
	}
};
</script>

<style scoped lang="scss">
	.dash-info-rapida {
		&.inactive {
			background: #d5d5d5!important;
		}
		.total {
			width: 100%;
			text-align: center;
			font-size: 4em;
			display: block;
			border-bottom: 1px solid #FFF;
		}
		.value {
			font-size: 1.2em;
			line-height: 1.25em;
			width: 100%;
			text-align: center;
			font-weight: bold;
			min-height: 2.5em;
		}
	}

	.select_dash {
		.form-group {
			margin-bottom: 0!important;
		}
	}
</style>
